<template>
    <v-data-table
        :headers="headers"
        :items="drivers"
        sort-by="driver_number"
        :search="search"
        class="elevation-1"
        :items-per-page="250"
        :footer-props="{
            itemsPerPageOptions: [100, 150, 250],
        }"
        @click:row="editItem"
        dense
    >
        <template v-slot:[`item.status`]="{ item }">
            <v-icon
                v-if="parseInt(item.status) === 0"
                color="red lighten-1"
                @click.stop="changeStatus(item, 1)">
                mdi-close
            </v-icon>
            <v-icon
                v-else
                color="success"
                @click.stop="changeStatus(item, 0)">
                mdi-check-bold
            </v-icon>
        </template>
        <template v-slot:[`item.phone1`]="{ item }">
            <span class="phone-number">{{ formatNumberPhone(item.phone1) }}<br />{{ formatNumberPhone(item.phone2) }}</span>
        </template>
        <template v-slot:[`item.licence_expiration`]="{ item }">
            <template v-if="item.licence_expiration">
                <span
                :class="daysLeftColor(item.licence_expiration)">
                    {{ item.licence_expiration }}<br />
                    <small>{{ item.licence_number }}</small>
                </span>
            </template>
            <template v-else>
                <small class="blue--text">brak danych</small>
            </template>
        </template>
        <template v-slot:[`item.driver_number`]="{ item }">
            <v-chip
                class="mr-1 px-2"
                small
                label
            >
                {{ item.driver_number  }}
            </v-chip> {{ item.side_number }}
        </template>
        <template v-slot:top>
            <v-toolbar flat dense>
                <v-toolbar-title>Lista kierowców</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details
                />
                <v-spacer/>
                <v-dialog v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            tile
                            dark
                            small
                            v-on="on"
                            @click="$refs.form ? $refs.form.reset() : null">
                            <v-icon class="mr-1">mdi-taxi</v-icon> Dodaj kierowcę
                        </v-btn>
                    </template>
                    <v-card>
                        <v-form
                            autocomplete="off"
                            ref="form"
                            v-model="validSaveDataDriver"
                            lazy-validation
                            @submit.prevent="save"
                        >
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.first_name"
                                                label="Imię"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.first_name"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.last_name"
                                                label="Nazwisko"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.last_name"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.driver_number"
                                                label="Numer (na kogucie)"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.driver_number"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="2" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.side_number"
                                                label="Numer (boczny)"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="7" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.address"
                                                label="Adres"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.city"
                                                label="Miasto"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.nip"
                                                label="NIP"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.licence_number"
                                                label="Numer licencji"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="5" class="py-0">
                                            <v-menu
                                                ref="licenceExpiration"
                                                v-model="licenceExpiration"
                                                :close-on-content-click="false"
                                                :return-value.sync="editedItem.licence_expiration"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="editedItem.licence_expiration"
                                                        label="Ważność licencji"
                                                        hint="Format: YYYY-MM-DD"
                                                        persistent-hint
                                                        clearable
                                                        prepend-icon="mdi-calendar-range"
                                                        v-on="on"
                                                        outlined
                                                        dense
                                                    />
                                                </template>
                                                <v-date-picker
                                                    v-model="editedItem.licence_expiration"
                                                    no-title
                                                    scrollable
                                                    :min="new Date().toISOString()"
                                                    @input="$refs.licenceExpiration.save(editedItem.licence_expiration)"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.phone1"
                                                label="Telefon"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.phone1"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.phone2"
                                                label="Telefon 2"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.car_brand"
                                                label="Marka samochodu"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.car_brand"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.car_model"
                                                label="Typ samochodu"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.car_model"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.car_color"
                                                label="Kolor samochodu"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.car_reg_no"
                                                label="Numer rejestracyjny"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.car_category"
                                                label="Kategoria"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.max_seats"
                                                label="Miejsca siedzące"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-switch
                                                color="blue"
                                                v-model="editedItem.has_van"
                                                label="Van"
                                                class="mt-0"
                                                false-value="0"
                                                true-value="1"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-switch
                                                color="blue"
                                                v-model="editedItem.has_sw"
                                                label="Kombi"
                                                class="mt-0"
                                                false-value="0"
                                                true-value="1"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="py-0">
                                            <v-switch
                                                color="blue"
                                                v-model="editedItem.has_terminal"
                                                label="Terminal"
                                                class="mt-0"
                                                false-value="0"
                                                true-value="1"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.comment"
                                                label="Komentarz"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="error" tile small outlined @click="close">Anuluj</v-btn>
                                <v-btn
                                    tile
                                    small
                                    type="submit"
                                    color="primary"
                                    :disabled="!validSaveDataDriver"
                                >
                                    Zapisz
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-btn
                    to="/"
                    class="ml-3"
                    dark
                    small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.action`]="{ item }">
            <v-btn tile text small @click="editItem(item)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="getListDrivers">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import Vue from "vue";

    export default {
        props: {
            message: {
                type: [String, Object],
                required: false
            },
            daysLeftColor: {
                type: Function
            },
            formatNumberPhone: {
                type: Function
            }
        },
        data: () => ({
            search: '',
            dialog: false,
            headers: [
                { text: 'Numer', value: 'driver_number' },
                {
                    text: 'Imię',
                    align: 'left',
                    value: 'first_name',
                },
                { text: 'Nazwisko', value: 'last_name' },
                { text: 'Nr. telefonu', value: 'phone1' },
                { text: 'Licencia', value: 'licence_expiration' },
                { text: 'Status', value: 'status' },
                { text: '', value: 'action', sortable: false },
            ],
            drivers: [],
            editedIndex: -1,
            editedItem: {
                first_name: '',
                last_name: '',
                driver_number: 0,
                side_number: 0,
                address: '',
                city: '',
                phone1: '',
                phone2: '',
                car_brand: '',
                car_model: '',
                car_color: '',
                car_reg_no: '',
                car_category: '',
                car_van: 0,
                has_sw: 0,
                has_van: 0,
                max_seats: 0,
                has_terminal: 0,
                comment: '',
                nip: '',
                licence_expiration: null,
                licence_number: null,
                licence_expires: false
            },
            defaultItem: {
                first_name: '',
                last_name: '',
                driver_number: 0,
                side_number: 0,
                address: '',
                city: '',
                phone1: '',
                phone2: '',
                car_brand: '',
                car_model: '',
                car_color: '',
                car_reg_no: '',
                car_category: '',
                car_van: 0,
                has_sw: 0,
                has_van: 0,
                max_seats: 0,
                has_terminal: 0,
                comment: '',
                nip: '',
                licence_expiration: null,
                licence_number: null,
                licence_expires: false
            },
            errors: [],
            validSaveDataDriver: true,
            licenceExpiration: null
        }),
        watch: {
            message: function (val) {
                if(typeof val.type !== 'undefined' && val.type === 'update_required_drivers') {
                    this.getListDrivers();
                }
            },
            dialog (val) {
                val || this.close()
            },
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nowy kierowca' : 'Edytuj kierowce - ' + this.editedItem.driver_number
            }
        },
        created () {
            this.getListDrivers()
        },
        methods: {
            getListDrivers() {
                this.$http.get('p/d/l') // Pobieranie listy kierowców
                    .then((response) => {
                        this.drivers = response.data;
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy kierowców.'
                        });
                    });
            },
            editItem (item) {
                this.editedIndex = item.id;
                this.$http.post('p/d/g/' + this.editedIndex) // Pobieranie danych kierowcy
                    .then((response) => {
                        this.editedItem = response.data;
                        this.dialog = true
                    })
                    .catch((error) => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania danych.'
                        });
                        this.errors = error.response.data.errors;
                    });
            },
            close () {
                this.dialog = false;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            },
            changeStatus (item, newStatus) {
                let editIndex = this.drivers.indexOf(item);

                if (editIndex > -1) {
                    item.status = newStatus;

                    this.$http.post('p/d/s/' + item.id + '/' + newStatus) // Zmiana statusu
                        .then((response) => {
                            if (response.status === 0) {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas zmiany statusu kierowcy.'
                                });
                            } else {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    title: 'Zaktualizowano status',
                                    text: 'Zaktualizowano status kierowcy ' + item.driver_number
                                });
                                Object.assign(this.drivers[editIndex], item);
                            }
                            Vue.prototype.$socket.sendObj({action:'update_4_all_drivers'});
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas zmiany statusu kierowcy.'
                            });
                        });
                }
            },
            save () {
                if(this.$refs.form.validate()) {
                    if (this.editedIndex > -1) {
                        this.$http.post('p/d/e/' + this.editedIndex, this.editedItem) // Zapis kierowcy
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Dane kierowcy zostały zaktualizowane!'
                                });
                                this.getListDrivers();
                                Vue.prototype.$socket.sendObj({action:'update_4_all_drivers'});
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas zmiany danych kierowcy.'
                                });
                                this.errors = (error.response ? error.response.data.errors : []);
                            });
                    } else {
                        this.$http.post('p/d/c', this.editedItem) // Zapis kierowcy
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Kierowca został dodany!'
                                });
                                Vue.prototype.$socket.sendObj({action:'update_4_all_drivers'});
                                this.drivers.push(this.editedItem);
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas dodania nowego kierowcy.'
                                });
                                this.errors = (error.response ? error.response.data.errors : []);
                            });
                    }
                } else {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        text: 'Popraw dane w formularzu.'
                    });
                }
            },
        },
    }
</script>
